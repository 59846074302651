<template>
    <div>
        <!-- My Details -->
        <vx-card class="billingFormart">
            <!-- Edit Profile Detail -->
            <div class="flex items-center justify-between">
                <h3 class="ml-5 font-large textColor">Billing Settings</h3>
            </div>
            <vs-divider class="mt-5 "></vs-divider>
            <p class="ml-5 textColor2">
                Manage your Workspace billing information from one place. Easily view
                update and manage your monthly billing <br />payment method and
                subscription details
            </p>
            <div class="flex flex-col items-center justify-center h-64 gap-2 mt-5 ml-5 border-2 border-teal-600 " v-if="isFethingData">
                <div class="lds-dual-ring"></div>
                <p >Fetching Billing Information...</p>
            </div>
            <template v-else>
                <div class="w-2/3">
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left">
                            <div class="w-full m-5 mr-0">
                                <h6 class="flex gap-1 pb-2 mt-5 items-bottom font-large textColor2">
                                    <span>Billing Email</span>
                                    <!-- <small class="cursor-pointer text-primary" v-if="!isEditing" @click="isEditing=true">(change)</small>
                                    <small class="cursor-pointer text-primary" v-else @click="updateBillingInformation">(save)</small> -->
                                </h6>
                                <vs-input icon-no-border class="w-full" placeholder="Email" v-model="organizationInfo.billingEmail" :readonly="!isEditing"/>
                            </div>
                        </vs-col>
                    </vs-row>
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left">
                            <div class="w-full m-5 mr-0">
                            <h6 class="pb-2 font-large textColor2">
                                Monthly Medical Director Fee
                            </h6>
                            <vs-input icon-no-border class="w-full" :value="`$${montlyMDFee.toFixed(2)} per month`" readonly />
                            </div>
                        </vs-col>
                        <div></div>
                    </vs-row>
                </div>
                <template v-if="$store.getters['organization/organizationPaymentStatus']">
                    <h6 class="pb-2 mt-6 ml-5 font-large textColor2">
                        Payment Method
                        <small class="cursor-pointer text-primary" @click="updatePaymentMethod">(change)</small>
                    </h6>
                    <div class="flex flex-wrap items-center justify-between mb-3"   
                    v-for="(payment, index) of organizationInfo.paymentMethods" :key="index"
                        >
                        <div class="w-2/3 px-6 py-2 ml-4 customBorder" v-if="payment.type == 'card' || payment.type == 'us_bank_account'">
                            <div v-if="payment.is_default" class="table-caption px-3 py-1 mb-1 text-sm font-bold customBorder textColor bg-grey-light" active="true">
                                Default
                            </div>
                            <div class="flex justify-between">
                                <p class="mt-2 ml-2 text-lg">{{ payment.billing_details.name || organizationInfo.organization.name }}</p>
                            </div>
                            <div class="flex items-top">
                                <img
                                    v-if="payment.type == 'card'"
                                    :src="require(`@/assets/images/payments/${iconUrl(payment.card.brand)}`)"
                                    height="40"
                                    width="40"
                                    alt="bank-logo"
                                    class="p-2 ml-2"
                                />
                                <img
                                    v-else
                                    src="@/assets/images/payments/bank.png"
                                    height="40"
                                    width="40"
                                    alt="bank-logo"
                                    class="p-2 ml-2"
                                />
                                <p class="flex-grow mt-4 ml-3 text-base">**** **** {{ payment.type == 'card' ? payment.card.last4 : payment.us_bank_account.last4 }}</p>
                                <p class="mt-4 text-base textPosition" v-if="payment.card">
                                    Card Expires at {{payment.card.exp_month}}/{{payment.card.exp_year}}
                                </p>
                                <p class="mt-4 text-base textPosition" v-else>
                                    {{payment.us_bank_account.bank_name}}
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
               
            </template>
        </vx-card>
        <template v-if="!isFethingData">
            <div class="mt-10 ">  
                <vx-card class="medicalDirectorFormat">       
                    <div class="flex items-center justify-between">
                        <h3 class="ml-5 font-large textColor">Clinics With Medical Director</h3>

                        <!-- <div class="buttonPosition"> -->
                        <!-- <vs-button size="default" color="primary" type="filled" v-if="medicalDirectors.length && notAllSubscribed"
                            @click="activateAll"
                            >Activate All</vs-button
                        >
                        </div> -->
                    </div>
                    <vs-divider class="mt-5 "></vs-divider>
                    <vs-table
                        class="mt-10 "
                        ref="table"
                        :data="medicalDirectors"
                    >
                        <template slot="thead">
                            <vs-th>Clinic Name</vs-th>
                            <vs-th>Medical Director Full Name</vs-th>
                            <vs-th>Price</vs-th>
                            <vs-th>Status</vs-th>
                            <vs-th width="25%">Action</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :data="item.id" :key="indextr" v-for="(item, indextr) in data">
                                <vs-td>{{ item.clinicName }}</vs-td>
                                <vs-td>{{ item.medicalDirectorFullname }}</vs-td>
                                <vs-td>${{ item.price }}</vs-td>
                                <vs-td>
                                    <p v-if="item.hasSubscription" class="text-primary">Subscription Activated</p>
                                    <p v-else class="text-danger">No current subscription</p>
                                </vs-td>
                                <vs-td>
                                    <vs-button :disabled="!$store.getters['organization/organizationPaymentStatus']" @click="activateSubscription(item)" class="my-2 " type="border" v-if="!item.hasSubscription && (item._id != currentClinicActivating)">
                                        Activate Subscription
                                    </vs-button>
                                    <p v-else-if="!item.hasSubscription && (item._id == currentClinicActivating)">Activating, please wait...</p>
                                    <p color="warning" class="my-2 text-regular" v-else>
                                        Contact freshclinics to stop this subscription
                                    </p>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vx-card>
            </div> 
        </template>
    </div>
 
</template>

<script>
import { MoreHorizontalIcon } from "vue-feather-icons";
import HttpClient from "@/http/axios/financeServer"
export default {
    components: {
        MoreHorizontalIcon,
    },
    data() {
        return {
            edit: false,
            userDetail: {},
            clinicName: "",
            montlyMDFee: 475,
            isEditing:false,
            organizationInfo:{
                billingEmail:'',
                organization:{}
            },
            medicalDirectors:[],
            isFethingData:true,
            currentClinicActivating:null
        };
    },
    created() {
        if(process.env.VUE_APP_REGION != 'US') {
            this.$router.push('/org-owner/notice-board')
        }
    },
    methods: {
        async getMedicalDirectors(organizationId) {
            try {
                const {data} = await HttpClient.post(
                    `payments/org/clinics-with-mds`,
                    {organizationId: organizationId}
                )
                this.medicalDirectors = data.clinics
            } catch(error) {
                console.log(erro)
                if (error.response.status != 404) {
                    this.$vs.notify({
                        title: "Payment Setup",
                        text: "Something went wrong file fetching payment methods",
                        color: "danger",
                    });
                }
            } finally {
                this.isFethingData = false
            }
        },
        iconUrl(cardType) {
            let iconFileName = null

            if (cardType == 'visa') iconFileName = 'visa.png'
            else if (cardType == 'mastercard') iconFileName = 'mastercard.png'
            else if (cardType == 'unionpay') iconFileName = 'unionpay.png'
            else if (cardType == 'amex') iconFileName = 'amex.png'
            else iconFileName = 'default.png'
            return `${iconFileName}`
        },
        async updateBillingInformation() {
            try {
                if (!this.organizationInfo.billingEmail) {
                    this.$vs.notify({
                        title: "Update Email",
                        text: "Cannot Update with empty email!",
                        color: "warning",
                    })
                    return
                }

                this.$vs.loading();
                
                const {data} = await HttpClient.put(
                    `payments/org/${this.$store.getters['organization/selectedOrganizationId']}/billing-email`,
                    { billingEmail: this.organizationInfo.billingEmail }
                )

                // this.$store.commit('organization/SET_SELECTED_BILLING_EMAIL', this.organizationInfo.billingEmail)

                // this.$vs.notify({
                //     title: "Billing Email",
                //     text: "Successfully updated billing email!",
                //     color: "success",
                // })
            } catch(error) {
                if (error.response.status != 404) {
                    this.$vs.notify({
                        title: "Billing Email",
                        text: "Something went wrong while updating information",
                        color: "danger",
                    });
                }
            } finally {
                this.$vs.loading.close();
                this.isEditing = false
                this.isFethingData = false
            }
        },
        async activateAll () {
            try {
                this.$vs.loading();
                await Promise.all(this.medicalDirectors.map(async (clinic) => {
                    if (!clinic.hasSubscription) {
                        return await this.activateSubscription(clinic)
                    }
                }))
            } finally {
                this.$vs.loading.close();
            }
           
        },
        async activateSubscription(clinic) {
            try {
                if (!this.$store.getters['organization/organizationPaymentStatus']) return
                this.$vs.loading();
                this.currentClinicActivating = clinic.clinicId

                const {data} = await HttpClient.post(
                    `payments/clinic/${clinic.clinicId}/activate`,
                )

                window.open(data.data.url, '_blank');
            } catch(error) {
                if (error.response && error.response.status != 404) {
                    this.$vs.notify({
                        title: "Clinic Subscription",
                        text: "Something went wrong while activating subscription",
                        color: "danger",
                    });
                } else if (error.response && error.response.status != 405) {
                    this.$vs.notify({
                        title: "Clinic Subscription",
                        text: error.response.data.message,
                        color: "danger",
                    });
                }
            } finally {
                this.$vs.loading.close();
                this.currentClinicActivating = null
                this.isFethingData = false
            }
            
        },
        async stopSubscription(clinic) {
            try {
                this.$vs.loading();
                this.currentClinicActivating = clinic.clinicId
                const {data} = await HttpClient.post(
                    `payments/clinic/${clinic.clinicId}/deactivate`,
                )
                clinic.hasSubscription = false
                this.$vs.notify({
                    title: "Clinic Subscription",
                    text: "Succesfully stopped subscription for " + clinic.clinicName,
                    color: "success",
                });
            } catch(error) {
                if (error.response && error.response.status != 404) {
                    this.$vs.notify({
                        title: "Clinic Subscription",
                        text: "Something went wrong while stopping subscription",
                        color: "danger",
                    });
                } else if (error.response && error.response.status != 405) {
                    this.$vs.notify({
                        title: "Clinic Subscription",
                        text: error.response.message,
                        color: "warning",
                    });
                }
            } finally {
                this.$vs.loading.close();
                this.currentClinicActivating = null
                this.isFethingData = false
            }
            
        },
        async updatePaymentMethod() {
            try {
                this.$vs.loading();
                const {data} = await HttpClient.post(
                    `payments/org/${this.organizationInfo.organization.id}/customerPortal`,
                )

                window.open(data.url, '_blank');
            } catch(error) {
                this.$vs.notify({
                    title: "Something went wrong while generating customer portal",
                    text: error.response.message,
                    color: "warning",
                });
            } finally {
                this.$vs.loading.close();
            }
        },
       
    },
    computed: {
        notAllSubscribed() {
            return this.medicalDirectors.length && this.medicalDirectors.some((clinic) => !clinic.hasSubscription)
        }
    },
    watch: {
        "$store.state.organization.selectedOrganization": {
            async handler(val) {
                if (val.organization) {
                    this.organizationInfo = {...this.$store.getters['organization/selectedOrganization']}
                    this.organizationInfo.billingEmail = this.$store.getters['organization/selectedOrganizationBillingEmail']
                    this.getMedicalDirectors(this.organizationInfo.organization.id)
                } else {
                    this.getMedicalDirectors(this.$store.getters['organization/selectedOrganizationId'])
                    this.isFethingData = false
                }
               
            },
            immediate:true
        },
        "$store.state.organization.selectedOrganizationBillingEmail": {
            handler(val) {
                this.organizationInfo.billingEmail = val
            },
            immediate:true
        }
    }
};
</script>

<style scoped>
.buttonPosition {
  position: absolute;
  right: 0;
  margin-right: 30px;
}
.billingFormart {
  border: 0;
  width: 75%;
}
.medicalDirectorFormat {
  border: 0;
  width: 75%;
}
.cardHeight {
  height: 14vh;
}
.textColor {
  color: #0e4333;
}
.textColor2 {
  color: #718096;
}
.customBorder{
  border: 1px solid rgba(122, 118, 118,0.2);
  border-radius: 8px;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #9f9191;
  border-color: #9f9191 transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>