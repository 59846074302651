// axios
import axios from "axios";

const domain = process.env.VUE_APP_FINANCE_SERVER_URL
const HttpClient = axios.create({
  baseURL: domain,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json"
  },
});

HttpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(
                    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
                );
  config.headers = {
    ...config.headers,
    Authorization: `${token}`,
  };
  return config;
});

// const responseErrorInterceptor = (error) => {
//   if (error.response && error.response.status == 401) {
//     location.reload();
//   }

//   return Promise.reject(error);
// };

// HttpClient.interceptors.response.use(
//   (response) => response,
//   responseErrorInterceptor
// );

export default HttpClient;
